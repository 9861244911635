














































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ImportHelper from '@/helper/ImportHelper';
import {v4 as uuIdv4} from 'uuid';
import {CSVAreaEnum} from '@/misc/CSVImport/CSVArea.enum';
import {CSVCustomerEnum} from '@/misc/CSVImport/CSVCustomer.enum';
import {CSVUserEnum} from '@/misc/CSVImport/CSVUser.enum';
import {CSVLocationEnum} from '@/misc/CSVImport/CSVLocation.enum';

interface ValidationState {
  error: boolean;
  row: number;
  column: number;
  errorText: string;
  errorRow: string;
}

@Component({
  components: {},
})
export default class ImportDetailsComponent extends Vue {

  /**
   * The csv-file to import
   */
  @Prop()
  public csvFile!: any;
  @Prop()
  public importType!: string;
  /**
   * The selected row which information will be displayed at the right side
   */
  public selectedRow: any[] = [];
  /**
   * The preview item which information will be displayed
   */
  public activePreviewItem: any = null;
  /**
   * bool to show the preview
   */
  public importPreview: boolean = false;
  /**
   * The import helper for this component
   */
  public importHelper!: ImportHelper;
  /**
   * The items to show in the preview
   */
  public previewItems: any[] = [];
  /**
   * The mails of the managers of the locations
   * @private
   */
  private managerMails: string[] = [];
  /**
   * The value for the importType in english
   * @private
   */
  private englishImportType: any = {customer: 'customer', location: 'object', area: 'area', user: 'employee'};
  /**
   * The enums to define the fields of the importItem
   * @private
   */
  private enums = {
    customer: CSVCustomerEnum,

    location: CSVLocationEnum,

    area: CSVAreaEnum,

    user: CSVUserEnum,
  };

  /**
   * The methods to create an import item. Indexed with the importType
   * @private
   */
  private createItem = {
    /**
     * Create an customerObject with the csv-data (array)
     * @param customerRow Array with the customer-data
     */
    customer: (customerRow: string): any => {
      if (customerRow) {
        return {
          id: uuIdv4(),
          name: customerRow[this.enums.customer.CUSTOMER_NAME],
          icon: 'mdi-account',
          data: [
            {
              title: this.$t('GENERAL.ADDRESS'),
              data: [
                customerRow[this.enums.customer.STREET] + ' ' + customerRow[this.enums.customer.HOUSE_NO],
                customerRow[this.enums.customer.POSTAL_CODE] + ' ' + customerRow[this.enums.customer.CITY],
                customerRow[this.enums.customer.COUNTRY],
              ],
            },
            {
              title: this.$t('GENERAL.CONTACT_PERSON'),
              data: [
                customerRow[this.enums.customer.CONTACT_LAST_NAME] + ' ' + customerRow[this.enums.customer.CONTACT_FIRST_NAME],
                customerRow[this.enums.customer.CONTACT_PHONE],
                customerRow[this.enums.customer.CONTACT_EMAIL],
              ],
            },
            {
              title: this.$t('GENERAL.ACCOUNT_STATUS'),
              data: this.importHelper.getStatus(customerRow[this.enums.customer.STATUS].trim()),
            },
          ],
        };
      }
    },

    /**
     * Create an locationObject with the csv-data (array)
     * @param locationRow Array with the location-data
     */
    location: (locationRow: string): any => {
      return {
        id: uuIdv4(),
        name: locationRow[this.enums.location.LOCATION_NAME],
        icon: 'mdi-home',
        data: [
          {
            title: this.$t('GENERAL.CUSTOMER'),
            data: locationRow[this.enums.location.CUSTOMER_NAME],
          },
          {
            title: this.$t('GENERAL.DESCRIPTION'),
            data: locationRow[this.enums.location.DESCRIPTION],
          },
          {
            title: this.$t('GENERAL.OBJECT_MANAGER'),
            data: this.managerMails,
          },
          {
            title: this.$t('GENERAL.ADDRESS'),
            data: [
              locationRow[this.enums.location.STREET] + ' ' + locationRow[this.enums.location.HOUSE_NO],
              locationRow[this.enums.location.POSTAL_CODE] + ' ' + locationRow[this.enums.location.CITY],
              locationRow[this.enums.location.COUNTRY],
            ],
          },
          {
            title: this.$t('GENERAL.CONTACT_PERSON'),
            data: [
              locationRow[this.enums.location.CONTACT_LAST_NAME] + ' ' + locationRow[this.enums.location.CONTACT_FIRST_NAME],
              locationRow[this.enums.location.CONTACT_PHONE],
              locationRow[this.enums.location.CONTACT_EMAIL],
            ],
          },
          {
            title: this.$t('GENERAL.ACCOUNT_STATUS'),
            data: this.importHelper.getStatus(locationRow[this.enums.location.STATUS].trim()),
          },
        ],
      };
    },

    /**
     * Create an areaObject with the csv-data (array)
     * @param areaRow Array with the area-data
     */
    area: (areaRow: string): any => {
      return {
        id: uuIdv4(),
        name: areaRow[this.enums.area.AREA_NAME],
        icon: 'mdi-checkbox-blank',
        data: [
          {
            title: this.$t('GENERAL.CUSTOMER'),
            data: areaRow[this.enums.area.CUSTOMER_NAME],
          },
          {
            title: this.$t('GENERAL.LOCATION'),
            data: areaRow[this.enums.area.LOCATION_NAME],
          },
          {
            title: this.$t('GENERAL.SIZE'),
            data: areaRow[this.enums.area.AREA_SIZE] + ' ' + this.$t('GENERAL.QM'),
          },
        ],
      };
    },

    /**
     * Create an userObject with the csv-data (array)
     * @param userRow Array with the user-data
     */
    user: (userRow: string): any => {
      return {
        id: uuIdv4(),
        name: userRow[this.enums.user.FIRST_NAME] + ' ' + userRow[this.enums.user.LAST_NAME],
        subTitle: userRow[this.enums.user.EMAIL],
        icon: 'mdi-account',
        data: [
          {
            title: this.$t('GENERAL.PHONE'),
            data: userRow[this.enums.user.PHONE],
          },
          {
            title: this.$t('GENERAL.EMAIL'),
            data: userRow[this.enums.user.EMAIL],
          },
          {
            title: this.$t('GENERAL.PERSONNEL_NUMBER'),
            data: userRow[this.enums.user.PERSONNEL_NUMBER],
          },
          {
            title: this.$t('GENERAL.ROLE'),
            data: userRow[this.enums.user.ROLE],
          },
          {
            title: this.$t('GENERAL.MAX_WEEK_HOURS'),
            data: userRow[this.enums.user.MAX_WEEKHOURS],
          },
        ],
      };
    },
  };

  /**
   * Methods for validating the items attributes
   * @private
   */
  private validateItem = {
    /**
     * Validates the attributes of a customer
     * @param lineArray
     * @param index
     * @param validationState
     */
    customer: (lineArray: string, index: number, validationState: ValidationState) => {
      // The column 10 is the customer email
      const customerMail = lineArray[this.enums.customer.CONTACT_EMAIL];
      this.importHelper.checkEmail(customerMail, this.enums.customer.CONTACT_EMAIL, index, validationState);

      const status = lineArray[this.enums.customer.STATUS];
      this.importHelper.checkStatus(status, this.enums.customer.STATUS, index, validationState);
    },

    /**
     * Validates the attributes of a location
     * @param lineArray
     * @param index
     * @param validationState
     */
    location: (lineArray: string, index: number, validationState: ValidationState) => {
      // The column 3 is the location-manager email(s). Could be more than one
      this.managerMails = lineArray[this.enums.location.MANAGER_EMAIL].split(',').map((mails) => mails.trim());
      const uniqueMails: any[] = this.importHelper.checkForDuplicates(this.managerMails, this.enums.location.MANAGER_EMAIL, index, validationState);

      // The column 12 is the contact-person email
      uniqueMails.push({mail: lineArray[this.enums.location.CONTACT_EMAIL], column: this.enums.location.CONTACT_EMAIL});
      for (const email of uniqueMails) {
        this.importHelper.checkEmail(email.mail, email.column, index, validationState);
      }

      // validate the status of the location
      const status = lineArray[this.enums.location.STATUS];
      this.importHelper.checkStatus(status, this.enums.location.STATUS, index, validationState);

      // validate the signNeeded of the location
      for (const sign of [this.enums.location.CUSTOMER_SIGN, this.enums.location.EMPLOYEE_SIGN]) {
        if (!this.importHelper.validateSign(lineArray[sign])) {
          validationState.error = true;
          validationState.column = sign;
          validationState.row = index;
          // row and column + 1, because user dont start counting at 0
          validationState.errorText = `${this.$t('IMPORT.NOTIFICATIONS.INVALID_SIGN', {
            row: index + 1,
            column: sign + 1,
            sign: lineArray[sign],
          })}`;
        }
      }
    },

    /**
     * Validates the attributes of an user
     * @param lineArray
     * @param index
     * @param validationState
     */
    user: (lineArray: string, index: number, validationState: ValidationState) => {
      // The column 5 is the user email
      const userMail = lineArray[this.enums.user.EMAIL];
      this.importHelper.checkEmail(userMail, this.enums.user.EMAIL, index, validationState);
    },
  };

  public constructor() {
    super();
    this.importHelper = new ImportHelper(this);
  }

  /**
   * Check if there is an selected item in the preview
   */
  get checkPreviewItems() {
    if (this.selectedRow.length === 0) {
      return null;
    }
    this.activePreviewItem = this.selectedRow[0];
    return this.selectedRow[0];
  }

  public mounted() {
    let lastField: number;
    if (this.importType === 'location') {
      lastField = 13;
    }

    const requiredFields = (Object.values(this.enums[this.importType])
        .filter((v: any) => typeof v === 'number' && (lastField ? v <= lastField : true)) as number[]);
    this.onShowPreview(requiredFields);
  }

  public async onShowPreview(requiredFields: number[]) {
    this.$emit('change-loadingState', this.$t('IMPORT.LOADING_STATE_1').toString());

    // initialise the validationState
    let validationState: ValidationState = {
      error: false,
      row: 0,
      errorText: `${this.$t('IMPORT.IMPORT_EMPTY')}`,
      column: 0,
      errorRow: '',
    };
    const allLines = await this.importHelper.parseCSV(this.csvFile);

    // Get the single lines of the csv-file
    allLines.forEach((lineArray: string, index: number) => {
      // read the first column for import validation and ignore invalid types. The english type is always supported
      if ([`${this.$t(`IMPORT.IMPORT_TYPE.${this.importType.toUpperCase()}`)}`.toLowerCase(), this.englishImportType[this.importType]]
          .find((object) => object === lineArray[0].toLowerCase())) {
        // check the required fields
        validationState = this.importHelper.validateFields(requiredFields, lineArray, index);

        // Some items may have no special attributes to validate. Currently just area
        if (this.validateItem[this.importType]) {
          this.validateItem[this.importType](lineArray, index, validationState);
        }

        // add location if no error occurred
        if (!validationState.error) {
          this.previewItems.push(this.createItem[this.importType](lineArray)!);
        }
      }
      // if an error occurs show an error message
      if (validationState.error) {
        this.$emit('empty-preview', validationState);
        return;
      }

      // loading
      if (this.importHelper.checkLoading()) {
        if (this.previewItems.length > 0) {
          this.importPreview = true;
        } else {
          this.$emit('empty-preview', validationState);
        }
      }
    });
  }
}
