export enum CSVLocationEnum {
    CUSTOMER_NAME = 1,
    LOCATION_NAME = 2,
    MANAGER_EMAIL = 3,
    STREET = 4,
    HOUSE_NO = 5,
    POSTAL_CODE = 6,
    CITY = 7,
    COUNTRY = 8,
    CONTACT_LAST_NAME = 9,
    CONTACT_FIRST_NAME = 10,
    CONTACT_PHONE = 11,
    CONTACT_EMAIL = 12,
    STATUS = 13,
    CUSTOMER_SIGN = 14,
    EMPLOYEE_SIGN = 15,
    DESCRIPTION = 16,

}
