import Papa, {ParseResult} from 'papaparse';
import ImportDetailsComponent from '@/components/shared/ImportDetails.component.vue';

export interface ValidationState {
  error: boolean;
  row: number;
  column: number;
  errorText: string;
  errorRow: string;
}

export default class ImportHelper {

  /**
   * The value to add to the loadingValue
   */
  private progressValue: number = 0;
  /**
   * The loading value that is shown in the loading animation
   */
  private value: number = 0;

  private emit80: boolean = true;

  private emit40: boolean = true;

  private importModal: ImportDetailsComponent;

  constructor(importModal: ImportDetailsComponent) {
    this.importModal = importModal;
  }

  private resetLoader() {
    this.value = 0;
    this.emit40 = true;
    this.emit80 = true;
  }

  /**
   * Read the csv with PapaParse
   */
  public async parseCSV(csvFile: any) {
    this.resetLoader();
    const result = (await new Promise<ParseResult<string>>((resolve) => {
      Papa.parse<string>(csvFile, {
        complete(results: ParseResult<string>) {
          resolve(results);
        },
        skipEmptyLines: true,
      });
    }));
    if (result.errors.length > 0) {
      this.importModal.$emit('parse-error', result.errors);
    }
    // emit the delimiter to send to the backend
    this.importModal.$emit('found-delimiter', result.meta.delimiter);

    // Get the progressValue to add to the loadingValue
    this.progressValue = 100 / result.data.length;
    return result.data;
  }

  /**
   * Checks if the loading value is greater or equal to 100 and no error occurs. In error case the returned value is false
   */
  public checkLoading() {
    // increase the loading value after finishing the line and send the value to the import component
    this.value += this.progressValue;

    // check loadingValue
    if (this.value >= 100) {
      this.importModal.$emit('finished-loading');
      return true;
    } else if (this.value > 80 && this.emit80) {
      this.emit80 = false;
      this.importModal.$emit('change-loadingState', this.importModal.$t('IMPORT.LOADING_STATE_2').toString());
    } else if (this.value > 40 && this.emit40) {
      this.emit40 = false;
      this.importModal.$emit('change-loadingState', this.importModal.$t('IMPORT.LOADING_STATE_3').toString());
    }
    return false;
  }

  /*  ===================================================
      =============== Methods for validation ============
      ===================================================   */
  /**
   * Validates if the fields are empty
   * @param requiredFields The fields that should not be empty
   * @param rowData The rowData to be read out
   * @param fileRow the index of he row
   */
  public validateFields(requiredFields: number[], rowData: string, fileRow: number): ValidationState {
    const validationState = {
      error: false,
      row: fileRow,
      column: 1,
      errorText: '',
      errorRow: '',
    };

    requiredFields.forEach((fieldNumber: number) => {
      if (!rowData[fieldNumber] || rowData[fieldNumber].length === 0) {
        validationState.error = true;
        validationState.column = fieldNumber;
        validationState.errorText = `${this.importModal.$t('IMPORT.NOTIFICATIONS.EMPTY', {
          row: fileRow + 1,
          column: fieldNumber + 1,
        })}`;
      }
    });
    return validationState;
  }

  public checkForDuplicates(emails: string[], field: number, index: number, validationState: ValidationState) {
    const checkedMails: any[] = [];
    for (const mailAddress of emails) {
      // validate if the same email-address is declared more than once
      if (checkedMails.findIndex((mails) => mails === mailAddress) > -1) {
        validationState.error = true;
        validationState.column = field; // e-mail field
        validationState.row = index;
        // row and column + 1, because user dont start counting at 0
        validationState.errorText = `${this.importModal.$t('IMPORT.NOTIFICATIONS.DOUBLED_EMAIL', {
          email: mailAddress,
          row: index + 1,
          column: field + 1,
        })}`;
        break;
      } else {
        checkedMails.push({mail: mailAddress, column: field});
      }
    }
    return checkedMails;
  }

  /**
   * Checks if an email is valid. If not, the validationState gets values
   * @param emailToCheck The email to check
   * @param field The column of the email
   * @param index The index of the line
   * @param validationState The validationState
   */
  public checkEmail(emailToCheck: string, field: number, index: number, validationState: ValidationState) {
    if (emailToCheck.length > 1 && !this.validateEmail(emailToCheck.trim())) {
      validationState.error = true;
      validationState.column = field; // e-mail field
      validationState.row = index;
      // row and column + 1, because user dont start counting at 0
      validationState.errorText = `${this.importModal.$t('IMPORT.NOTIFICATIONS.INVALID_EMAIL', {
        row: index + 1,
        column: field + 1,
        email: emailToCheck,
      })}`;
    }
  }

  /**
   * Validates an email. Uses a regex
   * @param email Email to validate
   */
  public validateEmail(email: string) {
    const emailExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailExp.test(String(email).toLowerCase());
  }

  /**
   * Validates if the signNeeded string is valid. Valid values are '', 'yes' and 'no' in english and the local language
   * @param sign the sign to validate
   */
  public validateSign(sign: string) {
    for (const needed of ['', `${this.importModal.$t('GENERAL.YES')}`, 'yes', `${this.importModal.$t('GENERAL.NO')}`, 'no']) {
      if (sign.toLowerCase() === needed.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks if a status is valid
   * @param status The status to check
   * @param field The column of the status
   * @param index The index of the line
   * @param validationState The validationState
   */
  public checkStatus(status: string, field: number, index: number, validationState: ValidationState) {
    if (status.length > 0 && !this.validateStatus(status)) {
      validationState.error = true;
      validationState.column = field;
      validationState.row = index;
      // row and column + 1, because user dont start counting at 0
      validationState.errorText = `${this.importModal.$t('IMPORT.NOTIFICATIONS.INVALID_STATUS', {
        row: index + 1,
        column: field + 1,
        stat: status,
      })}`;
    }
  }

  /**
   * Validate the status for the preview. Valid values are 'active' and 'inactive' in english and in the local language
   * @param status The status to validate
   */
  public validateStatus(status: string) {
    for (const stat of [`${this.importModal.$t('GENERAL.ACTIVE')}`, 'active', `${this.importModal.$t('GENERAL.INACTIVE')}`, 'inactive']) {
      if (status.toLowerCase() === stat.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the status to be shown in the preview
   * @param status The status to check
   */
  public getStatus(status: string) {
    // support locale language and english
    for (const statusType of [[`${this.importModal.$t('GENERAL.ACTIVE')}`, 'active'], [`${this.importModal.$t('GENERAL.INACTIVE')}`, 'inactive']]) {
      for (const stat of statusType) {
        if (status.toLowerCase() === stat.toLowerCase()) {
          return statusType[0];
        }
      }
    }
    return '';
  }
}
