













import {Component, Prop, Vue} from 'vue-property-decorator';
import DashboardTile from '@/models/DashboardTile';
import Company from '@/models/Company';
import {namespace} from 'vuex-class';

const CompanyStore = namespace('company');

@Component({})
export default class DashboardTileComponent extends Vue {
    @Prop()
    public item!: DashboardTile;

    @CompanyStore.Getter('activeCompany')
    private company?: Company;

    get tile() {
        return this.item;
    }

    public dashboardTileClicked() {
        this.$router.push({name: this.tile.route, params: {companyId: this.company!.id}});
    }
}
