export enum CSVCustomerEnum {
    CUSTOMER_NAME = 1,
    STREET = 2,
    HOUSE_NO = 3,
    POSTAL_CODE = 4,
    CITY = 5,
    COUNTRY = 6,
    CONTACT_LAST_NAME = 7,
    CONTACT_FIRST_NAME = 8,
    CONTACT_PHONE = 9,
    CONTACT_EMAIL = 10,
    STATUS = 11,
}
